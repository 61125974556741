import { Toaster } from 'solid-sonner';

export function AlertsContainer() {
  return (
    <Toaster
      richColors
      expand
      pauseWhenPageIsHidden
      visibleToasts={3}
      theme={document.body.classList.contains('dark') ? 'dark' : 'light'}
      toastOptions={{
        duration: 7000,
        classes: {
          toast: 'df-font-manrope bg-sky-50 rounded',
          title: 'df-font-beatrice font-semibold text-sm',
          description: 'font-medium',
          actionButton: 'font-semibold',
          cancelButton: 'font-semibold',
          closeButton: 'hover:opacity-90',
        },
      }}
    />
  );
}
